/** @jsx jsx */
/*eslint no-unused-vars: 0*/

/*

This component was shadowed for example purpose
Feel free to remove it.

*/

import React from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { Flex, Box, Text, Link } from 'rebass'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyLink from 'gatsby-link'
import { SocialIcon } from 'react-social-icons'

const validURL = str => {
  let pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(str)
}

const config = require("/data/siteConfig");

function Footer() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          gatsbyStorefrontConfig {
            email
            company
            location
            address
            phone
            workingDays
            workingHours
            socialNetworks
            footerLinks {
              name
              link
            }
          }
        }
      }
    }
  `)

  const {
    email,
    company,
    location,
    address,
    phone,
    workingDays,
    workingHours,
    socialNetworks,
    footerLinks,
  } = data.site.siteMetadata.gatsbyStorefrontConfig

  const { theme } = useThemeUI()

  const year = new Date().getFullYear()

  return (
    <Box bg="backgroundFooter" py={[1, 2, 3, 4]} mt={[2, 3, 4]}>
      <Box
        py={2}
        as="footer"
        width={1}
        style={{ maxWidth: 1300, height: '100%' }}
        mx="auto"
        px={2}
        pt={3}
      >
        <Flex alignItems="center" mb={[2, 3, 4]} flexWrap="wrap">
          <Flex
            width={[1, 1, 1]}
            justifyContent={['center', 'center']}
            flexWrap="wrap"
            mb={3}
          >
            {footerLinks
              ? footerLinks.map((link, index) => {
                  // If link is valid url use <a>
                  // else use gatsby-link
                  if (validURL(link.link)) {
                    return (
                      <Text key={index} mr={[3, 0]} my={[2, 0]}>
                        <Link href={link.link}>{link.name}</Link>
                      </Text>
                    )
                  } else {
                    return (
                      <Text key={index} mx={[3, 2]} my={[2, 0]}>
                        <GatsbyLink to={link.link} sx={theme.variants.link}>
                          {link.name}
                        </GatsbyLink>
                      </Text>
                    )
                  }
                })
              : ''}
          </Flex>

          <Flex
            width={[1, 1, 1]}
            justifyContent={['center', 'center', 'center']}
            mr="auto"
            pl={2}
            mb={3}
          >
            {socialNetworks
              ? socialNetworks.map((socialNetwork, index) => {
                  return (
                    <Box key={index}>
                      <SocialIcon
                        url={socialNetwork}
                        sx={{
                          height: 48,
                          width: 48,
                          margin: [2, 3],
                          opacity: 0.8,
                          ':hover,:focus,.active': {
                            opacity: 1,
                          }
                        }}
                        bgColor={theme.colors.primary}
                      />
                    </Box>
                  )
                })
              : ''}
          </Flex>
        </Flex>

        <Flex
          justifyContent={'center'}
        >
          <Box>
            <Text fontSize={[1, 2]}
              color={theme.colors.primary}
              sx={{
                textAlign: 'center'
              }}
            >
              © {year} {company ? <a href={config.siteCopyrightUrl} sx={theme.variants.link}>{company}</a> : ''}
              {address || location ? ' | ' : ''} {address} {location}
              {phone || workingDays || workingHours ? ' | ' : ''}
              {phone} {workingDays} {workingHours}
              {email ? ' | ' : ''}
              <a href={'mailto:' + config.siteEmail} sx={theme.variants.link}>
                {email}
              </a>
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default Footer
