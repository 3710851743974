const config = {
  siteName: 'Rowdy Brands',
  siteTitle: 'Rowdy Brands', // Site title.
  siteCopyright: 'Rowdy Brands Inc', // Used for copyright.
  siteCopyrightUrl: 'https://rowdybrands.com',
  siteTitleShort: 'Rowdy Brands', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: 'Rowdy Brands', // Alternative site title for SEO.
  siteLogo: 'images/logo.png', // Logo used for SEO and manifest. Relative to /static
  siteLogoFromRoot: '/images/logo.png',
  siteUrl: 'https://rowdybrands.store', // Domain of your website without pathPrefix.
  siteDescription: 'Rowdy Brands', // Website description used for RSS feeds/meta description tag.
  siteAuthor: 'Rowdy Brands', // Site author
  siteEmail: 'team@rowdybrands.com',
  siteLocation: 'USA',
  siteAddress: '123 Example St',
  sitePhone: '123-123-1234',
  siteWorkingDays: '',
  siteWorkingHours: '',
  siteSocialIG: 'https://instagram.com/example',
  siteBannerMsg: 'Order before Nov 18th to receive by the white-out game',
  favIcon: 'src/images/logo.png',
  baseUrl: 'https://rowdybrands.store',
  pageTitle: 'Shop',
  menuTitle: 'Menu Title',
  subtitle: 'Rowdy Brands',
  description: 'Rowdy Brands Inc',
  primaryColor: '#1CB1F5',
  secondaryColor: '#35BAF6',
  tertiaryColor: '#CCEDFC',
  backgroundColor: '#fff',
};

module.exports = config;
